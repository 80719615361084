/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'jquery-ujs'
import 'remotipart/jquery.iframe-transport'
import 'remotipart/jquery.remotipart'
// import Rails from '@rails/ujs'
// Rails.start()

import 'init'
import 'forms'
import 'semantic_ui'

// imports dinâmicos para as páginas específicas
document.addEventListener('DOMContentLoaded', function (event) {
  // infelizmente esta parece ser a única maneira de testar se um módulo existe e importá-lo condicionalmente...
  try {
    // import(`../${document.body.className}`)
    require(`../${document.body.className}`)
  } catch (error) {
    // console.log('Arquivo não existe', error)
  }
})

// Para integração com ActionText
require('trix')
require('@rails/actiontext')
