const { fecharPopup } = require('../semantic_ui');

/* eslint no-console:0 */
$(function () {
  // no index de pessoas, prepara o link de exportação de CSV dos resultados com a URL da última busca feita
  $('form#busca_de_pessoas').on('ajax:success', function (e, resposta) {
    const links = document.querySelectorAll('[data-exportar]');
    const params = new URLSearchParams(new FormData(e.currentTarget)).toString();

    links.forEach((link) => {
      // define a url do link como sendo seu data-url + seu estado atual serializado como querystring
      link.href = `${link.dataset.url}?${params}`;
    });
  });

  // gerenciamento de popups associados às abas (eventos, afastamentos, documentos etc)
  // TODO: refatorar e mover os itens pros seus arquivos específicos
  $(document).on('popup-solicitada', function (e, args) {
    const { idPopup } = args;
    // preparar_campo_upload $(idPopup)
    // $(idPopup).find('.hide').hide()

    // libera campo de órgão de destino apenas para cedências
    $('#afastamento_tipo').on('change', function (e) {
      if ($(this).val() == 'cedencia') {
        $(idPopup).find('.field.cedencia').show();
      } else {
        $('#afastamento_orgao_de_destino').val('');
        $(idPopup).find('.field.cedencia').hide();
      }
    });

    // eventos de vínculo
    $('form#new_evento_de_vinculo').on('ajax:success', function (e, resposta) {
      fecharPopup();
    });
  });
});
