/* eslint no-console:0 */
import './semantic.min';
const { decamelizeKeys } = require('humps');

export function abrirPopup({ seletor = '#modal', url = '', texto = '' } = {}) {
  if (texto) {
    $(seletor).find('.content').html(texto);
  }

  $(seletor)
    .modal({ allowMultiple: true, detachable: false })
    .modal('setting', 'closable', false) //$(seletor).data('fechavel') != 'false'
    .modal('setting', 'transition', 'scale')
    .modal('show');
}

// único jeito que achei de disponibilizar funções para *.js.erb. Como contornar?
window.abrirPopup = abrirPopup;

export function fecharPopup({ seletor = '#modal' } = {}) {
  $(seletor).modal('hide');
}

import { i18nCalendar, formatadorDeData } from './calendar';

export function inicializarSemantic(contexto = 'body') {
  contexto = $(contexto);

  // contexto.find('.ui.accordion').accordion({ animateChildren: false });
  // inicializa somente accordions raiz em relação ao contexto
  // Nested Accordion content not stay open
  // https://github.com/fomantic/Fomantic-UI/issues/254
  contexto.find('.ui.accordion:not(.accordion .ui.accordion)').accordion();
  contexto.find('.ui.accordion[data-trigger]').each(function () {
    $(this).accordion({
      selector: { trigger: $(this).data('trigger') },
      animateChildren: false,
    });
  });

  // pegamos todos os forms do contexto pra baixo...
  $.uniqueSort(contexto.find('form').addBack().filter('form')).each(function () {
    // ...e automaticamente pegamos todos os selects required dentro de cada form
    // e os marcamos como required para o Fomantic.
    // (único jeito de ganhar validação no cliente para select's e evitar submit sem valores!)

    // extrai os ids dos campos select dentro de divs com classe required
    const selectsRequired = $(this)
      .find('.required.field select')
      .map((_, select) => $(select).attr('id'))
      .get();

    if (selectsRequired.length) {
      // https://fomantic-ui.com/behaviors/form.html#specifying-validation-rules
      $(this).form({
        keyboardShortcuts: false,
        fields: selectsRequired.reduce((acc, curr) => ({ ...acc, [curr]: 'empty' }), {}),
      });
    }
  });

  contexto.find('select[multiple]').dropdown();
  contexto
    .find('select.ui.dropdown')
    .not('[data-cascade-url]')
    .dropdown({ fullTextSearch: true, clearable: true, ignoreDiacritics: true, sortSelect: true });
  // .not('[data-cascade-url], [multiple]')

  contexto.find('.ui.checkbox').checkbox();
  contexto.find('[data-content], [title]').popup();
  contexto.find('.tabular.menu .item').tab({ alwaysRefresh: true });
  contexto.find('.ui.sticky').sticky();
  // contexto.find('.button.group + .dropdown.button').dropdown() // grupos de botões
  contexto.find('.dropdown.button').dropdown(); // grupos de botões

  contexto.find('.ui.calendar').calendar({
    type: 'date',
    ampm: false,
    monthFirst: false, // evita bug de parsear data digitada dd/mm como mm/dd
    text: i18nCalendar,
    formatter: formatadorDeData,
  });

  contexto.find('.checkbox.todos').checkbox({
    onChange: function () {
      $(this)
        .closest('.fields')
        .find('.checkbox')
        .not('.todos')
        .checkbox($(this).is(':checked') ? 'check' : 'uncheck');
    },
  });
}

$(function () {
  inicializarSemantic();

  $('.message .close').on('click', function () {
    $(this).closest('.message').transition('fade');
  });

  $('#usuario-logado .ui.dropdown').dropdown();

  // ao se selecionar um perfil do seletor do menu principal,
  // procede-se com a reautenticação pelo novo perfil selecionado.
  $('#usuario-logado').on('click', '[data-id]', function (e) {
    e.preventDefault();

    $.ajax({
      url: $(e.currentTarget).attr('href'),
      type: 'PUT',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify({
        data: decamelizeKeys({
          type: 'sessao',
          attributes: {
            perfilId: $(e.currentTarget).data('id'),
          },
        }),
      }),

      // após a troca de perfil, redirecionamos para a tela indicada pelo data-redirecionar do link
      success: function (data) {
        window.location.assign($(e.currentTarget).data('redirecionar'));
      },
    });
    return false;
  });

  $(document).on('popup-solicitada', function (e, args) {
    const { idPopup } = args;
    inicializarSemantic(idPopup);
    abrirPopup({ seletor: idPopup });
  });

  $(document).on('refresh-ajax', function (e, args) {
    const { view } = args;
    inicializarSemantic(view);
  });
});
