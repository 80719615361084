/* eslint no-console:0 */
$(function () {
  // força categoria AD para cargos de origem
  $('#cargo_origem')
    .closest('.ui.checkbox')
    .checkbox({
      onChange: function (e) {
        if ($('#cargo_origem').is(':checked')) {
          $('#cargo_categorias')
            .dropdown('set exactly', 'a_disposicao')
            .closest('.dropdown')
            .addClass('disabled')
        } else {
          $('#cargo_categorias').dropdown('clear').closest('.dropdown').removeClass('disabled')
        }
      },
    })
})
