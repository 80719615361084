$.ajaxSetup({
  xhrFields: {
    withCredentials: true,
  },
})

$(function () {
  // transfere o click em grupo de botões para para o ícone de setinha (o botão real)
  $('body').on('click', '.button.group', function (e) {
    $(e.currentTarget).next().trigger('click')
  })

  // permite que links definam um data-redirecionar="<url>" para onde redirecionar após clicado
  $('[data-redirecionar]')
    .not('form')
    .on('ajax:success', function (e) {
      location.href = $(this).data('redirecionar')
    })
})
