/* eslint no-console:0 */
// import { abrirPopup } from 'semantic_ui'

import { decamelizeKeys, camelizeKeys } from 'humps';

const popupDeSelecionarPerfil = '#selecionar-perfil';

$(function () {
  $('form').on('submit', (e) => {
    $.ajax({
      type: 'POST',
      url: $(e.currentTarget).attr('action'),
      data: JSON.stringify({
        data: {
          type: 'sessao',
          attributes: decamelizeKeys({
            usuario: $('#usuario').val(),
            senha: $('#senha').val(),
            perfilId: $('#perfil_id').val(),
          }),
        },
      }),
      contentType: 'application/json',
      dataType: 'json',

      // trata o login com sucesso
      success: function (data) {
        const { data: sessao, included } = camelizeKeys(data);

        if (sessao.attributes['valida?']) {
          // login está ok e perfil foi selecionado. Avançamos...
          location.href = $('form').data('redirecionar') || '/';
        } else {
          // ...senão, há múltiplos perfis para escolha. Construímos a lista deles na UI...
          // Preenche <fulano> em "Olá <fulano>, escolha um perfil..."
          $('.usuario').html(sessao.attributes.nome);

          // construímos um objeto cujas chaves são os ids dos perfis disponíveis para seleção,
          // e cujos valores são as unidades atreladas a esses perfis, retornadas no campo included da resposta JSONAPI do autenticador.
          const unidadesDosPerfis = {};
          (included || [])
            .filter((i) => i.type == 'perfil_de_acesso')
            .forEach((perfil) => {
              unidadesDosPerfis[perfil.id] = included.find(
                (i) => i.type == 'unidade_organizacional' && i.id == perfil.relationships.unidadeOrganizacional.data.id,
              );
            });

          const htmlDePerfis = Object.keys(unidadesDosPerfis).map(
            (perfilId) =>
              `<li data-id="${perfilId}">
                <a href="javascript:void(0)" data-selecionar-perfil>${unidadesDosPerfis[perfilId].attributes.nome}</a>
              </li>`,
          );

          // ...e exibimos a popup para seleção
          if (htmlDePerfis.length) {
            $('#perfis').html(htmlDePerfis.join(''));
            abrirPopup({ seletor: popupDeSelecionarPerfil });
          } else {
            abrirPopup({ texto: 'Erro ao buscar perfis para seleção.' });
          }
        }
      },

      error: function (xhr, textStatus, errorThrown) {
        console.log('deu ruim', xhr, textStatus, errorThrown);

        if (!xhr.responseJSON) {
          abrirPopup({ texto: errorThrown });
        } else if (xhr.responseJSON.mensagem) {
          abrirPopup({ texto: xhr.responseJSON.mensagem });
        } else if (xhr.responseJSON.errors) {
          abrirPopup({ texto: xhr.responseJSON.errors.map((e) => e.detail).join(', ') });
        } else {
          abrirPopup({ texto: 'Erro inesperado ao tentar login' });
        }
      },
    });

    return false;
  });

  $(popupDeSelecionarPerfil).on('click', '[data-selecionar-perfil]', function (e) {
    $('form').find('#perfil_id').val($(e.currentTarget).parent('li').data('id')).end().trigger('submit');
  });
});
