$(function () {
  // Permite carregar listagens com forms de busca em telas como :index
  $('form[data-busca]').on('ajax:success', function (e, resposta) {
    $('table').removeClass('hidden').find('tbody').html(resposta.html).end().find('caption').hide();

    const total = $('table > tbody > tr').length;
    if (total > 0) {
      $('table > caption').show().find('.total').html(total);
    }
  });
});
