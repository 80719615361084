import { inicializarSemantic } from 'semantic_ui'

$(function () {
  $('#perfil_id').on('change', function () {
    const id = $(this).val()
    if (id) {
      const url = $(this.form).attr('action').replace(/\d+/, id)
      $(this.form).attr('action', url).trigger('submit')
    }
  })

  $('#form_perfis').on('ajax:success', function () {
    inicializarSemantic('#form_permissoes')
  })

  $('body').on('ajax:success', '#form_permissoes', function () {
    $(this).find('[data-retorno]').remove()
    $(this).append('<p class="ui success visible message" data-retorno>Permissões salvas.</p>')
  })
})
