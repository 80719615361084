const { decamelizeKeys } = require('humps');

$(function () {
  const checkboxesDeFuncionalidades = ':checkbox[name*=funcionalidade_id]';

  $('form')
    .on('change', checkboxesDeFuncionalidades, function () {
      const campoDeDelegacao = $(this).closest('.field').next();
      if (this.checked) {
        campoDeDelegacao.show();
      } else {
        campoDeDelegacao.hide();
      }
    })
    .on('submit', (e) => {
      const payload = $('#form_permissoes')
        .find('input:enabled:checkbox:checked')
        .map((i, checkbox) => ({
          type: 'permissao',
          attributes: {
            chefeDeveDelegar: $(checkbox).closest('li').find(':radio:checked').val(),
          },
          relationships: {
            funcionalidade: { data: { type: 'funcionalidade', id: $(checkbox).val() } },
          },
        }))
        .get();

      const filtro = {
        entidade: $('#atribuivel_entidade').val(),
        id: $('#atribuivel_id').val(),
      };
      const url = `${$(e.currentTarget).attr('action')}?${$.param(filtro)}`;

      $.ajax({
        url,
        data: JSON.stringify({ data: decamelizeKeys(payload) }),
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',

        // trata o sucesso ao salvar as permissões
        success: function (data) {
          // $('#retorno').remove();
          // $('<p id="retorno" class="ui success message">Permissões salvas.</p>').insertAfter('form');
          window.location.assign($('#permissoes').data('redirecionar'));
        },
      });
      return false;
    });

  $(checkboxesDeFuncionalidades).trigger('change');
});
