$(function () {
  // Tratar campos array
  $('form')
    .on('keypress', '.inserir_no_array', function (e) {
      if (e.key === 'Enter') {
        e.preventDefault();

        const valor = $(this).val();
        const novoId = Math.floor(Math.random() * 100000);
        if (valor) {
          const htmlDoNovoItem = $(e.currentTarget)
            .nextAll('.template-valor')
            .clone()
            .find(':input:hidden')
            .prop('id', `valor_${novoId}`)
            .removeAttr('disabled')
            .val(valor)
            .end()
            .find('.label')
            .prepend(valor)
            .end()
            .html();

          // insere o html do novo item do array no form
          $(this).val('').after($(htmlDoNovoItem));
        }

        return false;
      }
    })
    .on('click', '.array .delete', function (e) {
      // Remover um valor do array
      e.preventDefault();
      $(e.currentTarget).closest('.label').prev().addBack().remove();
    });
});
