// configuração de suporte para o componente Calendar do Semantic UI - https://fomantic-ui.com/modules/calendar.html
export const i18nCalendar = {
  days: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  today: 'Hoje',
  now: 'Agora',
  am: 'AM',
  pm: 'PM',
}

export const formatadorDeData = {
  date: function (data, settings) {
    if (!data) return ''
    const dia = data.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 })
    const mes = (data.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 })
    const ano = data.getFullYear()
    return `${dia}/${mes}/${ano}`
  },
}
