const { fecharPopup } = require('../semantic_ui');

/* eslint no-console:0 */
$(function () {
  $(document).on('popup-solicitada', function (e, args) {
    const { idPopup } = args;

    const categorias = {
      identificacao: 'documentos_pessoais',
      recebimento_de_credencial: 'outros',
    };

    // fixa a categoria para certos tipos de documentos, definidos acima
    $(idPopup)
      .find('#documento_tipo')
      .on('change', function (e) {
        const tipoSelecionado = $(this).val();

        if (categorias.hasOwnProperty(tipoSelecionado)) {
          $('#documento_categoria')
            .dropdown('set exactly', categorias[tipoSelecionado])
            .closest('.dropdown')
            .addClass('disabled');
        } else {
          $('#documento_categoria').closest('.dropdown').removeClass('disabled');
        }
      })
      .trigger('change');
  });
});
