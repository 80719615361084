$(function () {
  $('form').on('click', '[data-cy=botao_disponibilidade]', function (e) {
    const username = $('#credencial_username').val();
    if (username) {
      const url = $(e.currentTarget).data('url').replace(':username', username);
      $.get(url);
    }
  });

  $('#credencial_username').on('keydown', function (e) {
    const char = e.key;
    if (!char.match(/[A-Za-z0-9+#\.\-]/)) e.preventDefault();
  });

  // injeta na url do preview o username ainda a ser escolhido
  $('#preview').on('click', function (e) {
    e.preventDefault();
    $.get($(e.currentTarget).attr('href').replace(':username', $('#credencial_username').val()));
    return false;
  });

  // desobriga e esconde campos de senha se ativação da credencial será remota
  $('.ui.radio.checkbox.ativacao').checkbox({
    onChange: function () {
      if ($(this).val() == 'remota') {
        $('#credencial_senha, #credencial_confirmar_senha').attr('required', false);
        $('form .field.senha').hide(250);
      } else {
        $('#credencial_senha, #credencial_confirmar_senha').attr('required', true);
        $('form .field.senha').show(250);
      }
    },
  });

  $('.button.desativacao').on('click', function (e) {
    $('#popup-desativacao').modal('show');
  });
});
