/* eslint no-console:0 */
$(function () {
  $('form').on('ajax:success', function (e, resposta) {
    $('table').removeClass('hidden').find('tbody').html(resposta.html);
  });

  // Ramais: comportamento de botões de CRUD
  $('.ramais')
    .on('click', '.adicionar', function (e) {
      $('.ramais > .criacao').show().find(':visible:input:first').trigger('focus');
    })
    .find('.criacao')
    .hide()
    .on('click', '.cancelar', function (e) {
      $('.ramais > .criacao').hide();
    });

  $('.ramais > .ramal')
    .on('click', '.editar', function (e) {
      $(e.currentTarget)
        .closest('.dados')
        .hide()
        .end()
        .closest('.ramal')
        .find('.edicao')
        .show()
        .find(':visible:input:first')
        .trigger('focus');
    })
    .on('click', '.cancelar', function (e) {
      $(e.currentTarget).closest('.edicao').hide().end().closest('.ramal').find('.dados').show();
    })
    .find('.edicao')
    .hide();
});
