import Highcharts from 'highcharts'

// Radialize the colors
Highcharts.setOptions({
  colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
    return {
      radialGradient: {
        cx: 0.5,
        cy: 0.3,
        r: 0.7,
      },
      stops: [
        [0, color],
        [1, Highcharts.Color(color).brighten(-0.3).get('rgb')], // darken
      ],
    }
  }),
})

// Build the chart
Highcharts.chart('quadro_funcional', {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    height: 250,
  },
  title: false,
  tooltip: {
    pointFormat: '{series.name}: <b>{point.y}</b>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      startAngle: -90,
      endAngle: 90,
      center: ['50%', '75%'],
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.y}',
        style: {
          color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
        },
        connectorColor: 'silver',
      },
    },
  },
  series: [
    {
      name: 'Vínculo',
      data: $('#quadro_funcional').data('dados'),
    },
  ],
})
